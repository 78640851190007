import url from 'url';
import flow from 'lodash/flow';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { connect } from 'react-redux';

import withAuthHandling from '../../decorators/auth/withAuthHandling';
import unauthRedirectHandler from '../../utils/auth/unauthRedirectHandler';
import getUserDataUrl from '../../utils/paths/getUserDataUrl';
import FavoritesLoader from './FavoritesLoader';
import canShowFavorites from './utils/canShowFavorites';

class FavoritesRoute extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const routeTo = this.evaluateRoute();
    if (routeTo) {
      props.history.replace(routeTo);
    }
    this.state = { routeTo };
  }

  get userName() {
    return get(this.props, 'match.params.userName', '');
  }

  evaluateRoute() {
    const userName = this.userName;

    if (canShowFavorites(this.props, userName)) {
      return null;
    }

    return url.format({
      ...this.props.location,
      pathname: getUserDataUrl({ userName }),
    });
  }

  render() {
    return <FavoritesLoader />;
  }
}

function mapStateToProps(state) {
  return {
    app: state.app,
    me: state.me,
    auth: state.auth,
  };
}

export default flow(
  withAuthHandling({ unauthRedirectHandler }),
  connect(mapStateToProps),
)(FavoritesRoute);
