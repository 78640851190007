import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';
import { Component } from 'react';
import Header from '../header/Header';
import ErrorPage from './ErrorPage';

export default class GlobalErrorBoundary extends Component {
  static propTypes = {
    localizations: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error, {
      extra: {
        appMessage: 'Error caught in GlobalErrorBoundary',
        meta: errorInfo,
      },
    });
  }

  render() {
    const { children, localizations } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <>
          <Header showSidebarToggle={false} isMinimalHeader unlinkedLogo />
          <ErrorPage localizations={localizations} shouldHideSearch />
        </>
      );
    }

    return children;
  }
}
