import classNames from 'clsx';
import interpolateComponents from 'interpolate-components';
import PropTypes from 'prop-types';
import { Component } from 'react';
import {
  ERROR_TEXT,
  ERROR_TITLE,
} from 'src/common/constants/localizations/error';
import cssVars from 'src/common/styles/variables';
import { LANDING_SUGGEST_SEARCH_V2 } from '../../constants/localizations/landing';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import commonCss from '../../styles/common.module.scss';
import SearchBar from '../search/Searchbar';
import searchCss from '../search/search.module.scss';
import ErrorPageUnderlineLink from './ErrorPageUnderlineLink';
import css from './errorPage.module.scss';

const hintStyle = {
  fontSize: '14px',
  lineHeight: '19px',
  color: cssVars['--cloud-grey'],
  fontFamily: cssVars['--primary-font'],
};

const searchIconProps = {
  width: cssVars['--medium-icon-size'],
  height: cssVars['--medium-icon-size'],
  fill: cssVars['--cloud-grey'],
};

class ErrorPage extends Component {
  static propTypes = {
    shouldHideSearch: PropTypes.bool,
    shouldHideTitle: PropTypes.bool,
    localizations: PropTypes.object,
    customErrorTitleKey: PropTypes.string,
    customErrorSubtitleKey: PropTypes.string,
  };

  // this component is NOT always wrapped with a context provider
  static contextType = LocationAndLocalizationContext;

  getLocalizedText(key) {
    const { getLocalizedText } = this.context || {};
    return getLocalizedText?.(key) || this.props.localizations?.[key];
  }

  render() {
    const {
      shouldHideSearch,
      shouldHideTitle,
      localizations,
      customErrorTitleKey,
      customErrorSubtitleKey,
    } = this.props;
    const { getLocalizedText } = this.context || {};
    const titleTextKey = customErrorTitleKey || ERROR_TITLE;
    const subtitleTextKey = customErrorSubtitleKey || ERROR_TEXT;
    const subtitleText =
      this.getLocalizedText(subtitleTextKey) ||
      this.getLocalizedText(ERROR_TEXT);

    return (
      <div className={commonCss.contentContainer}>
        {!shouldHideSearch && (
          <div
            id="searchBarContainer"
            data-testid="searchBarContainer"
            className={classNames(
              css.searchBarContainer,
              searchCss.searchBarContainer,
            )}
          >
            <SearchBar
              iconContainerClassName={searchCss.searchIconContainer}
              searchBarClassName={searchCss.searchBarWrapperClass}
              hintStyle={hintStyle}
              iconProps={searchIconProps}
              hintText={
                getLocalizedText?.(LANDING_SUGGEST_SEARCH_V2) ||
                localizations?.[LANDING_SUGGEST_SEARCH_V2]
              }
            />
          </div>
        )}
        <div
          id="errorTextAndVideoContainer"
          data-testid="errorTextAndVideoContainer"
          className={classNames(css.errorTextAndVideoContainer, {
            [css.withoutSearch]: shouldHideSearch,
          })}
        >
          <div data-testid="errorOnAirImage" className={css.errorOnAirImage}>
            <img
              src="/assets/img/error/signOff.png"
              className={css.image1}
              role="presentation"
            />
            <img
              src="/assets/img/error/signOn.png"
              className={css.image2}
              role="presentation"
            />
            <img
              src="/assets/img/error/textLight.png"
              className={css.image3}
              role="presentation"
            />
          </div>
          <div
            id="errorTextContainer"
            data-testid="errorTextContainer"
            className={css.errorTextContainer}
          >
            {!shouldHideTitle && (
              <h1 data-testid="errorTextTitle" className={css.errorTextTitle}>
                {getLocalizedText?.(titleTextKey) ||
                  localizations?.[titleTextKey]}
              </h1>
            )}
            {subtitleText && (
              <h3 data-testid="errorTextSubtitle" className={css.errorText}>
                {interpolateComponents({
                  mixedString: subtitleText,
                  components: {
                    a: <ErrorPageUnderlineLink />,
                  },
                })}
              </h3>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ErrorPage;
// TODO: Refresh this with new images/animated svg (also, refresh PR env)
