import { useCallback, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { setInfoBannerMessage as setInfoBannerMessageActionCreator } from 'src/common/actions/app';
import { DISCORD_GEORESTRICTIONS_INFO_BANNER_DEFAULT } from 'src/common/constants/localizations/discord';
import useActions from 'src/common/hooks/useActions';
import { useDiscordGeorestrictedUsersCountMessage } from 'src/common/hooks/useDiscordGeorestrictedUsersCountMessage';
import { LocationAndLocalizationContext } from 'src/common/providers/LocationAndLocalizationProvider';
import { selectDiscordGeolocations } from 'src/common/selectors/app';
import {
  selectIsNowPlayingDialogOpen,
  selectNowPlayingProfileGuideItem,
} from 'src/common/selectors/dialog';

export function useDiscordGeorestrictionsBanner() {
  const nowPlayingProfileGuideItem = useSelector(
    selectNowPlayingProfileGuideItem,
  );
  const discordGeolocations = useSelector(selectDiscordGeolocations);
  const georestrictedUsersCountMessage =
    useDiscordGeorestrictedUsersCountMessage(nowPlayingProfileGuideItem, true);
  const isNowPlayingDialogOpen = useSelector(selectIsNowPlayingDialogOpen);
  const actions = useActions({
    setInfoBannerMessage: setInfoBannerMessageActionCreator,
  });
  const { getLocalizedText } = useContext(LocationAndLocalizationContext);
  const setInfoBannerMessage = useCallback(() => {
    actions.setInfoBannerMessage(
      isNowPlayingDialogOpen
        ? georestrictedUsersCountMessage
        : getLocalizedText(DISCORD_GEORESTRICTIONS_INFO_BANNER_DEFAULT),
    );
  }, [
    actions.setInfoBannerMessage,
    isNowPlayingDialogOpen,
    georestrictedUsersCountMessage,
    getLocalizedText,
  ]);

  useEffect(() => {
    if (discordGeolocations?.length <= 1) {
      actions.setInfoBannerMessage(null);
      return;
    }

    setInfoBannerMessage();
  }, [actions.setInfoBannerMessage, setInfoBannerMessage, discordGeolocations]);
}
