import config from '../../config';
import css from './errorPage.module.scss';

const ErrorPageUnderlineLink = ({ children }) => (
  <a
    /* biome-ignore lint/a11y/useSemanticElements: TODO: explore changing to input or button */
    role="button"
    tabIndex="0"
    data-testid="linkToOrigin"
    target="_self"
    href={config.secureOrigin}
    className={css.underlineLink}
  >
    <span>
      {children}
      <i />
    </span>
  </a>
);

export default ErrorPageUnderlineLink;
