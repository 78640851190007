import { fetchWithRetry } from '@tunein/web-utils';
import flow from 'lodash/flow';
import { isInDiscordIFrame } from 'src/common/utils/discord';
import {
  DISCORD_CHANNEL_ID,
  DISCORD_GUILD_ID,
  DISCORD_USER_ID,
} from '../../../constants/queryParams';
import { hostModes } from './constants';

export function getDiscordAvatarUrl(user) {
  if (!user) {
    return 'https://cdn.discordapp.com/embed/avatars/1.png';
  }

  if (user.avatar) {
    return `https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png?size=256`;
  }

  const discriminator = Number.parseInt(user.discriminator) % 5;

  return `https://cdn.discordapp.com/embed/avatars/${discriminator}.png`;
}

/**
 * Get the user's guild-specific avatar uri
 * If none, fall back to the user profile avatar
 * If no main avatar, use a default avatar
 */
function getUserAvatarUri(guildId, guildAvatarHash, authenticatedUser) {
  if (guildAvatarHash) {
    return `https://cdn.discordapp.com/guilds/${guildId}/users/${authenticatedUser.user.id}/avatars/${guildAvatarHash}.png?size=256`;
  }

  return getDiscordAvatarUrl(authenticatedUser.user);
}

function getUserNickName(nickName, authenticatedUser) {
  return (
    nickName ??
    `${authenticatedUser.user.username}${authenticatedUser.user.discriminator}`
  );
}

function fetchUserGuildInfo(guildId, authenticatedUser, errorLogger) {
  const onError = async (error) => {
    const isErrorInstance = error instanceof Error;
    const errorToLog = isErrorInstance
      ? error
      : new Error(`Response status: ${error.status}`);

    if (!isErrorInstance) {
      Object.assign(errorToLog, {
        status: error.status,
        statusText: error.statusText,
        body: await error.text().catch(() => null),
      });
    }

    errorLogger(errorToLog);
  };

  return fetchWithRetry(
    `https://discord.com/api/users/@me/guilds/${guildId}/member`,
    {
      headers: {
        Authorization: `Bearer ${authenticatedUser.access_token}`,
      },
      onRetry: onError,
    },
  ).catch(onError);
}

/**
 * This helper function is used to fetch the user's guild-specific avatar and nickname
 * We're going to return either the guild specific values, and fallback to the user's
 * default values, if no guild-specific values are available
 */
export async function fetchGuildsUserAvatarAndNickname(
  guildId,
  authenticatedUser,
  errorLogger,
) {
  const guildsMembersRead =
    isInDiscordIFrame() && guildId
      ? await fetchUserGuildInfo(guildId, authenticatedUser, errorLogger)
      : null;

  return {
    id: authenticatedUser.user.id,
    nick: getUserNickName(guildsMembersRead?.nick, authenticatedUser),
    avatarUri: getUserAvatarUri(
      guildId,
      guildsMembersRead?.avatar,
      authenticatedUser,
    ),
  };
}

function generateUserId() {
  // Set queryParam to a random 19-character numerical string
  return '1'.padEnd(19, Math.random().toString().slice(2));
}

export function getUserId(queryParam) {
  const currentStoredValue = window.sessionStorage.getItem(queryParam);

  if (currentStoredValue) {
    return currentStoredValue;
  }

  const randomString = generateUserId();

  window.sessionStorage.setItem(queryParam, randomString);

  return randomString;
}

export function getMockUserInfo() {
  const id = getUserId(DISCORD_USER_ID);
  const discriminator = id.charCodeAt(1) % 5;

  return {
    id,
    nick: 'mock_user',
    avatarUri: getDiscordAvatarUrl({ id, discriminator }),
  };
}

export function getMockParticipantInfo() {
  const id = generateUserId();

  return {
    id,
    username: `mock_user_${id}`,
    discriminator: id.charCodeAt(1) % 5,
  };
}

export function getDefaultDiscordState() {
  return {
    isHost: true,
    canControlPlayback: true,
    activity: {
      activityMode: hostModes.hosted,
    },
  };
}

export function getMockDiscordState() {
  const user = getMockUserInfo();

  return {
    ...getDefaultDiscordState(),
    user,
    participants: [
      user,
      getMockParticipantInfo(),
      getMockParticipantInfo(),
      getMockParticipantInfo(),
      getMockParticipantInfo(),
      getMockParticipantInfo(),
    ],
  };
}

/**
 * We're using session storage for user_id, guild_id, and channel_id
 * This way the user/guild/channel will be maintained until the tab is closed, even if you refresh
 * Session storage will generate new unique mocks for each tab you open
 * Any of these values can be overridden via query parameters
 * i.e. if you set https://my-tunnel-url.com/?user_id=test_user_id
 * this will override the session user_id value
 */
export function getMockDiscordConfig() {
  const mockUserId = getUserId(DISCORD_USER_ID);
  const mockGuildId = getUserId(DISCORD_GUILD_ID);
  const mockChannelId = getUserId(DISCORD_CHANNEL_ID);
  const discriminator = String(mockUserId.charCodeAt(0) % 5);
  const commandMocks = {
    authenticate: () => ({
      access_token: 'mock_token',
      user: {
        username: mockUserId,
        discriminator,
        id: mockUserId,
        avatar: null,
        public_flags: 1,
      },
      scopes: [],
      expires: new Date(2112, 1, 1).toString(),
      application: {
        description: 'mock_app_description',
        icon: 'mock_app_icon',
        id: 'mock_app_id',
        name: 'mock_app_name',
      },
    }),
  };

  return { mockGuildId, mockChannelId, commandMocks };
}

function bufferStreamOffset(timestamp, streamOffsetMs) {
  const buffer = Date.now() - new Date(timestamp).getTime();
  const newStreamOffset = buffer + streamOffsetMs;

  return Number.isNaN(newStreamOffset) ? 0 : newStreamOffset;
}

export function streamOffsetToSeconds(timestamp, streamOffsetMs) {
  try {
    const seconds = bufferStreamOffset(timestamp, streamOffsetMs) / 1000;

    return Math.max(0, seconds);
  } catch (e) {
    return 0;
  }
}

export function streamOffsetToPercentageInt(
  timestamp,
  streamOffsetMs,
  durationSeconds,
) {
  try {
    const percentageInt =
      bufferStreamOffset(timestamp, streamOffsetMs) / durationSeconds / 10;

    return Math.max(0, Math.min(100, percentageInt));
  } catch (e) {
    return 0;
  }
}

export function convertParticipantsMetadataToLength(state) {
  if (!Array.isArray(state?.participants)) {
    return state;
  }

  return { ...state, participants: state.participants.length };
}

export function filterUserInfo(state) {
  if (!state?.user?.id) {
    return state;
  }

  return { ...state, user: { id: state.user.id } };
}

export function filterCurrentHostInfo(state) {
  if (!state?.currentHost?.id) {
    return state;
  }

  const { id, bot } = state.currentHost;

  return { ...state, currentHost: { id, bot } };
}

export function convertStateForLogging(state) {
  return flow(
    convertParticipantsMetadataToLength,
    filterUserInfo,
    filterCurrentHostInfo,
  )(state);
}

export function getRichPresenceState(details, nowPlayingTitle) {
  return {
    activity: {
      type: 0, // docs don't explain what `type` does, but examples show a static 0, so we'll do the same
      ...(nowPlayingTitle && { state: nowPlayingTitle }),
      details,
    },
  };
}
