import { isStation } from 'src/common/utils/guideItemTypes';

// exported for testing
export function getImageTemplate(layout, url) {
  switch (layout) {
    case 'BrickTile':
      return {
        properties: {
          brickImage: {
            imageUrl: url,
          },
        },
      };
    case 'LandscapeImage': {
      return {
        properties: {
          landscapeImage: {
            imageUrl: url,
          },
        },
      };
    }
    case 'Tile': {
      return { image: url };
    }
    default:
      break;
  }
}

const behaviorToActionMap = {
  tune: 'Play',
  profile: 'Profile',
  browse: 'Browse',
  subscribe: 'Subscribe',
  directsubscribe: 'Subscribe',
};

// exported for testing
export function generatePlayActions(guideId, title) {
  return {
    play: {
      canPlay: true,
      isLive: isStation(guideId),
      guideId,
      subscriptionRequired: false,
      scrollableNowPlaying: false,
    },
    profile: {
      destinationInfo: {
        id: guideId,
        seoName: title || 'Station',
      },
    },
  };
}

// exported for testing
export function generateProfileActions(guideId, title) {
  return {
    profile: {
      canViewProfile: true,
      destinationInfo: {
        id: guideId,
        seoName: title || 'Station',
        requestType: behaviorToActionMap.profile,
      },
    },
  };
}

// exported for testing
export function generateBrowseActions(guideId, title) {
  return {
    browse: {
      canBrowse: true,
      guideId,
      destinationInfo: {
        id: guideId,
        seoName: title || 'Station',
        requestType: behaviorToActionMap.browse,
      },
    },
  };
}

// exported for testing
export function getSubscribeSuccessRedirectPath(deepLink) {
  try {
    const deepLinkUrl = new URL(deepLink);
    const successDeepLink = deepLinkUrl.searchParams.get('successdeeplink');
    const guideId = successDeepLink.split('/').pop();

    return `/radio/${guideId}/`;
  } catch (e) {
    return '';
  }
}

// exported for testing
export function generateSubscribeActions(guideId, title, url) {
  return {
    subscribe: {
      canSubscribe: true, // TODO: revisit as it may be the logic determining, if the user can subscribe.
      autoPurchase: false,
      guideId,
      successDeeplink: getSubscribeSuccessRedirectPath(url),
    },
  };
}

const actionsGeneratorMap = {
  [behaviorToActionMap.profile]: generateProfileActions,
  [behaviorToActionMap.browse]: generateBrowseActions,
  [behaviorToActionMap.tune]: generatePlayActions,
  [behaviorToActionMap.subscribe]: generateSubscribeActions,
};

export function getItemTemplate({
  priority,
  itemLayout,
  title,
  subtitle,
  imageUrl,
  url,
  pinned,
  created,
  updated,
  brazeCardId,
  screenId,
  categorySeoInfo,
  containerIndex,
  containerLayout,
  containerTitle,
}) {
  const { behavior, guideId } =
    /^tunein:\/\/(?<behavior>.\w+)(\/(?<guideId>.\d+))*/.exec(url)?.groups ||
    {};
  const actionName = behaviorToActionMap[behavior];

  return {
    hasActions: true,
    index: priority,
    containerIndex,
    title,
    ...{ isTitleVisible: !!title },
    subtitle,
    presentation: {
      layout: itemLayout,
    },
    guideId,
    brazeCardId,
    screenId,
    pinned,
    created,
    updated,
    actions: actionsGeneratorMap[actionName]?.(guideId, title, url),
    behaviors: {
      default: {
        actionName,
      },
    },
    reporting: {
      page: {
        guideId: categorySeoInfo?.guideId,
        aliasName: categorySeoInfo?.alias,
      },
      container: {
        id: '',
        type: containerLayout,
        name: containerTitle,
        position: containerIndex,
      },
    },
    ...getImageTemplate(itemLayout, imageUrl),
  };
}

export function getContainerTemplate(index, containerLayout, containerTitle) {
  return {
    index,
    brazeContainer: true,
    title: containerTitle,
    presentation: {
      layout: containerLayout,
    },
    children: [],
  };
}
