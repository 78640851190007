import { pick } from '@tunein/web-utils';
import { selectIsAuthenticated } from 'src/common/selectors/auth';

const STATE_FIELDS = ['app', 'me', 'auth'];

export default function canShowFavorites(state, userName) {
  const { app, me, auth } = pick(state, STATE_FIELDS);
  const stateUserName = me?.details?.userName;
  return !!(
    app.enableFavorites &&
    selectIsAuthenticated({ auth }) &&
    stateUserName &&
    userName === stateUserName
  );
}
