import EventEmitter from 'events';
import { fetchWithRetry, isObject, rejectAfter } from '@tunein/web-utils';
import debounce from 'lodash/debounce';
import config from 'src/common/config';
import { v4 as uuidv4 } from 'uuid';
import accountCategory from '../../../constants/analytics/categoryActionLabel/account';
import {
  DISCORD_HOSTED_MODE_ACTIVATED,
  DISCORD_SHARED_MODE_ACTIVATED,
} from '../../../constants/localizations/discord';
import { playerStatuses } from '../../../constants/playerStatuses';
import { app } from '../../../constants/reporting/sandboxCategoryActionProps';
import { isInDiscordIFrame } from '../../../utils/discord';
import { isProdEnv } from '../../../utils/environment';
import { safeJsonStringify } from '../../../utils/object/safeJsonStringify';
import {
  DISCORD_CLIENT_ID,
  MAX_DISCORD_ACTIVITY_API_RETRIES,
  RESOLVED_PLAYER_STATUSES,
  URL_MAPPINGS,
  URL_MAPPINGS_OPTIONS,
  authConfig,
  discordCloseCodes,
  discordErrorCodes,
  discordErrorMessages,
  discordPostMessageEvents,
  discordSdkConfig,
  hostModes,
  playRateToPlaySpeedEnumMap,
  playRates,
  playSpeedEnumToPlayRateMap,
  playSpeeds,
} from './constants';
import {
  convertStateForLogging,
  fetchGuildsUserAvatarAndNickname,
  getMockDiscordConfig,
  getRichPresenceState,
  streamOffsetToPercentageInt,
  streamOffsetToSeconds,
} from './utils';

const { local, discordProductionLocal } = config.discordApi.domains;
const localApiDomain = isProdEnv() ? discordProductionLocal : local;
const stoppedPlayerStatuses = {
  [playerStatuses.idle]: true,
  [playerStatuses.stopped]: true,
  [playerStatuses.failed]: true,
};

export class DiscordManager extends EventEmitter {
  #isInitialized = false;
  #debugMode;
  #api;
  #actions;
  #stateRef;
  #discordSdk;
  #sessionId;
  #activityState;
  #participants = [];
  #userInfo = {};
  #cachedActivityListening = {};
  #currentHost;
  #unsubscribeFromActivity;
  #unsubscribeFromPreviousActivity;
  #joinTimestamp;
  #modeStartTimestamp;
  #requestTunePromise;
  #subscriptionRetryCount = 0;
  #isSettingHost = false;
  #initialParticipantsResolver;
  #updateListeningTimeoutId;

  get canControlPlayback() {
    return (
      this.isHost || this.#activityState?.activityMode === hostModes.shared
    );
  }

  get #currentState() {
    return {
      currentHost: this.#currentHost,
      isHost: this.isHost,
      user: this.#userInfo,
      activity: this.#activityState,
      activityId: this.#discordSdk?.instanceId,
      sessionId: this.#sessionId,
      participants: this.#participants,
      canControlPlayback: this.canControlPlayback,
      isInitialized: this.#isInitialized,
    };
  }

  get #sessionContext() {
    return {
      activityId: this.#discordSdk.instanceId,
      discordUserId: this.#userInfo.id,
    };
  }

  get #reportingMetrics() {
    return {
      instanceId: this.#discordSdk?.instanceId || '',
      serverId: this.#discordSdk?.guildId || '',
      channelId: this.#discordSdk?.channelId || '',
      userId: this.#userInfo.id || '',
      duration: Date.now() - (this.#joinTimestamp || Date.now()),
      modeDuration: Date.now() - (this.#modeStartTimestamp || Date.now()),
    };
  }

  get #isLongestActiveUser() {
    return this.#participants.slice(-1)[0]?.id === this.#userInfo.id;
  }

  get #store() {
    return this.#stateRef.current || {};
  }

  async #handleDiscordError(errorCode) {
    let shouldReportError = true;

    switch (errorCode) {
      case discordErrorCodes.oauthDeniedViaOutsideModalClick:
      case discordErrorCodes.oauthDeniedViaButtonClick:
        await this.#actions
          .reportDiscordOauthDenied({
            instanceId: this.#reportingMetrics.instanceId,
            errorCode: errorCode?.toString(),
            errorMessage: discordErrorMessages[errorCode],
          })
          .catch(() => {});
        shouldReportError = false;
        break;
      default:
        break;
    }

    return shouldReportError;
  }

  #reportFatalError(message, error) {
    const { launch, fatal } = app.props.discord.types;

    return this.#actions.reportDiscordError(
      this.#isInitialized ? fatal : launch,
      [message, error?.customMessage, error?.message].filter(Boolean).join('|'),
    );
  }

  /**
   * A fatal error indicates that recovery attempts have been exhausted, so we should tear down the app and exit, closing the Activity for the user
   * @param message
   * @param error
   */
  async #onFatalError(message, error) {
    const shouldReportError = await this.#handleDiscordError(error?.code);

    if (shouldReportError) {
      await Promise.all([
        this.#reportFatalError(message, error),
        this.#logError(message, error),
      ]).catch(() => {});
    }

    this.destroy();
  }

  #logError = async (message, error, context) => {
    const errorLog = {
      message: `DiscordManager: ${message || error?.message}`,
      context: {
        state: convertStateForLogging(this.#currentState),
        error,
        originalErrorMessage: error?.message,
        // Attempt to record stack trace, if error doesn't have one
        ...(!(error instanceof Error) && {
          errorStack: new Error('Custom error stack').stack,
        }),
        ...context,
      },
    };

    if (this.#debugMode) {
      // eslint-disable-next-line no-console
      console.error('[DEBUG][DISCORD]', safeJsonStringify(errorLog, 2));
    }

    await this.#actions.logClientError(errorLog).catch(() => {});
  };

  #debug(message, meta = null, includeInVerboseMode = true) {
    if (!this.#debugMode) {
      return;
    }

    // eslint-disable-next-line no-console
    console.log('[DEBUG][DISCORD]', message, {
      meta,
      state: this.#currentState,
    });

    if (!(this.#debugMode === 'verbose' && includeInVerboseMode)) {
      return;
    }

    this.#actions.logClientInfo({
      message: `DiscordManager: ${message}`,
      context: {
        state: convertStateForLogging(this.#currentState),
        meta: isObject(meta) ? convertStateForLogging(meta) : meta,
        durationMs: Math.round(performance.now() - window.discordLaunchTiming),
      },
    });
    window.discordLaunchTiming = performance.now();
  }

  /**
   * Enabled when the `discord.debugModeEnabled` Config Setting is true or a `debug` query parameter includes `discord`, (e.g. `?debug=wmp-discord-ads`).
   * `discord.debugModeEnabled` can also be set to `verbose` to send debug logs to the logging service.
   * @param isDebugModeEnabled
   */
  #setDebugMode(isDebugModeEnabled = false) {
    this.#debugMode =
      isDebugModeEnabled ||
      new URLSearchParams(window.location.search)
        .get('debug')
        ?.includes('discord');
  }

  #getHostFromParticipants() {
    return this.#participants.find(
      ({ id }) => id === this.#activityState?.hostId,
    );
  }

  /**
   * When testing in a browser in Manual Discord Mode (i.e., ?discordMode=true), we have to use the mock SDK because the real SDK will only run in the Discord client.
   */
  async #initDiscordSdk() {
    // Note: library has import side effects that references window. Don't import directly in files that run on the server.
    const { DiscordSDK, DiscordSDKMock, patchUrlMappings } = await import(
      '@discord/embedded-app-sdk'
    );

    if (isInDiscordIFrame()) {
      this.#discordSdk = new DiscordSDK(DISCORD_CLIENT_ID, discordSdkConfig);

      patchUrlMappings(URL_MAPPINGS, URL_MAPPINGS_OPTIONS);
    } else {
      const { mockGuildId, mockChannelId, commandMocks } =
        getMockDiscordConfig();

      this.#discordSdk = new DiscordSDKMock(
        DISCORD_CLIENT_ID,
        mockGuildId,
        mockChannelId,
      );
      // The mock SDK instance ID is a 19-digit string, but the real SDK uses a UUID, which Platform also expects
      this.#discordSdk.instanceId = uuidv4();

      this.#discordSdk._updateCommandMocks(commandMocks);
    }

    this.#debug('DiscordSDK initialized');
    await rejectAfter(
      this.#discordSdk.ready(),
      10_000,
      new Error('DiscordSDK ready timeout'),
    );
    this.#debug('DiscordSDK ready');
  }

  async #authorizeWithDiscord(shouldRetryOnOutsideClick = true) {
    try {
      const { code } = await this.#discordSdk.commands.authorize(authConfig);

      this.#actions.reportDiscordOauthAccepted({
        instanceId: this.#reportingMetrics.instanceId,
      });
      this.#debug('Authorization code received', code);

      return code;
    } catch (error) {
      // If user clicked outside of modal, present it one more time, just in case it was accidental.
      if (
        shouldRetryOnOutsideClick &&
        error?.code === discordErrorCodes.oauthDeniedViaOutsideModalClick
      ) {
        return this.#authorizeWithDiscord(false);
      }

      error.customMessage = 'Error authorizing with Discord';

      throw error;
    }
  }

  /**
   * The auth flow is as follows:
   *  1. Authorizes with Discord, which asks the user for scoped permissions, then returns an auth code.
   *  2. Auth code is sent to Gemini Web server, which exchanges it for an access token.
   *  3. Access token is used to authenticate with Discord SDK and retrieve the user's Discord id and other high-level metadata.
   *  4. Auth data is used to fetch the user's nickname and avatar URL. We also attach the id to #userInfo from the original auth data.
   *
   * @returns {Promise<void>}
   */
  async #authenticate() {
    const code = await this.#authorizeWithDiscord();

    // Note: if we ever find a need to add a second Gemini-Web API for Discord, we can make this a Web Api Client service
    // HACK: including the serial query param addresses a cookie issue in the Discord app on iOS (see docs/features/Discord.md for more info)
    const { accessToken } = await fetchWithRetry(
      `${localApiDomain}/discord/token?serial=${this.#store.serial}`,
      { post: { code } },
    ).catch((error) => {
      error.customMessage = 'Error fetching Discord access token';
      throw error;
    });

    this.#debug('Access token received', accessToken);

    const authData = await this.#discordSdk.commands
      .authenticate({
        access_token: accessToken,
      })
      .catch((error) => {
        error.customMessage = 'Error authenticating with Discord';
        throw error;
      });

    this.#debug('Auth Data', authData);

    this.#userInfo = await fetchGuildsUserAvatarAndNickname(
      this.#discordSdk.guildId,
      authData,
      (error) => this.#logError('Error fetching user info from Discord', error),
    ).catch((error) => {
      error.customMessage = 'Error fetching user info from Discord';
      throw error;
    });

    this.#debug('User info', this.#userInfo);

    // Platform Oauth only works with real Discord tokens, so we'll skip this step when the app isn't running in the
    // Discord client (i.e., in an iframe)
    if (isInDiscordIFrame()) {
      await this.#actions
        .loginWithOauth(accountCategory.labels.discord, {
          userId: this.#userInfo.id,
          token: accessToken,
        })
        .catch((error) => {
          error.customMessage = 'Error during oauth with TuneIn';
          throw error;
        });

      this.#debug('Authed with TuneIn');
    }

    this.emit('authed');
  }

  /**
   * As it's possible for the Host to leave without assigning a new Host, we'll attempt to assign the longest active
   * user in #participants as the Host if we can't find the original Host (as identified by #activityState.hostId).
   * This function is executed on every participant update and on every activity state update. We are debouncing this
   * function to avoid unnecessary calls.
   */
  #maybeSetFallbackHost = debounce(async () => {
    if (
      this.#activityState &&
      !this.#isSettingHost &&
      !this.isHost &&
      !this.#getHostFromParticipants() &&
      this.#isLongestActiveUser
    ) {
      this.#debug(
        'Activity state host not found in participants list. Assigning fallback host.',
      );

      const success = await this.setHost(this.#userInfo.id);

      if (!success) {
        this.#onFatalError('Max set fallback host retries reached');
      }
    }
  }, 1000);

  #setParticipants = (response) => {
    this.#participants = response?.participants?.length
      ? response.participants
      : [];

    if (!this.#participants.length) {
      return;
    }

    if (this.#initialParticipantsResolver) {
      this.#initialParticipantsResolver();
      this.#initialParticipantsResolver = null;
      this.#debug('Received initial participants');
    }

    this.#debug('Set participants', this.#participants, false);

    if (this.#isInitialized) {
      this.#actions.setDiscordState(this.#currentState);
      // Safety net to guarantee that a Host is set. See method comment for more info.
      this.#maybeSetFallbackHost();
    }
  };

  async #subscribeToParticipants() {
    if (!isInDiscordIFrame()) {
      this.#participants = [this.#userInfo];

      this.#debug('Participants', this.#participants);
      return;
    }

    try {
      // Note: library has import side effects that references window. Don't import directly in files that run on the server.
      const { Events } = await import('@discord/embedded-app-sdk');
      const { resolve, promise: participantsSubscriptionPromise } =
        Promise.withResolvers();

      this.#initialParticipantsResolver = resolve;
      this.#discordSdk.subscribe(
        Events.ACTIVITY_INSTANCE_PARTICIPANTS_UPDATE,
        this.#setParticipants,
      );

      return rejectAfter(
        participantsSubscriptionPromise,
        10_000,
        new Error('Participants subscription timeout'),
      );
    } catch (error) {
      error.customMessage = 'Error subscribing to participants';
      throw error;
    }
  }

  async #maybeRetrySubscribe() {
    if (this.#subscriptionRetryCount++ >= MAX_DISCORD_ACTIVITY_API_RETRIES) {
      this.#onFatalError('Max subscribe retries reached');
      return;
    }

    try {
      await this.#subscribeToActivityState();
    } catch (error) {
      // Log and retry on unexpected thrown errors for good measure
      this.#logError('Unexpected error from subscribe retry', error);
      this.#maybeRetrySubscribe();
    }
  }

  #onSubscribeUpdate = (activityState) => {
    if (this.#unsubscribeFromPreviousActivity) {
      this.#unsubscribeFromPreviousActivity();
      this.#unsubscribeFromPreviousActivity = null;
    }

    if (activityState instanceof Error) {
      this.#maybeRetrySubscribe();
      return;
    }

    // Platform may send a SERVER_SHUTDOWN event when k8s is shutting down a server and creating a new one, in which case
    // we have 30 seconds to resubscribe to the activity (which is ample time).
    if (activityState?.code === 'SERVER_SHUTDOWN') {
      this.#debug('Server shutdown');
      this.#maybeRetrySubscribe();
      return;
    }

    // The initial activity state is always an empty object. Adding logger in case we ever get one after initialization.
    if (!activityState?.activityId && !activityState?.sessionId) {
      if (this.#isInitialized) {
        this.#debug(
          'Received empty activity after initialization',
          activityState,
        );
      }

      return;
    }

    // The next activity state is the join response, which just includes the session id
    if (activityState?.sessionId) {
      this.#joinTimestamp = Date.now();
      this.#sessionId = activityState.sessionId;
      this.#debug('Session ID received', this.#sessionId);
      return;
    }

    try {
      if (!this.#isInitialized && this.#isLongestActiveUser) {
        this.#modeStartTimestamp = Date.now();

        const { instanceId, serverId, channelId, userId } =
          this.#reportingMetrics;
        this.#actions.reportDiscordActivityInitiated({
          instanceId,
          serverId,
          channelId,
          userId,
        });
      }

      if (
        this.#activityState &&
        this.#activityState.activityMode !== activityState.activityMode
      ) {
        this.#modeStartTimestamp = Date.now();

        if (!this.isHost) {
          this.#actions.showMessage(
            activityState.activityMode === hostModes.hosted
              ? DISCORD_HOSTED_MODE_ACTIVATED
              : DISCORD_SHARED_MODE_ACTIVATED,
          );
        }
      }

      // On successful updates, we reset the retry count.
      this.#subscriptionRetryCount = 0;
      this.#activityState = activityState;

      if (this.#activityState?.hostId !== this.#currentHost?.id) {
        this.#currentHost =
          this.#getHostFromParticipants() || this.#currentHost;
      }

      this.#actions.setDiscordState(this.#currentState);
      // Safety net to guarantee that a Host is set. See method comment for more info.
      this.#maybeSetFallbackHost();

      // As the subscription connection is asynchronous, we are emitting a `ready` event here on the first update.
      // Otherwise, we would potentially be giving the user a false impression that the app is ready when it's not
      // (which would exit if the subscription connection fails).
      if (!this.#isInitialized) {
        this.#isInitialized = true;
        this.emit('ready');
        this.#debug('Activity loaded');

        const { instanceId, userId } = this.#reportingMetrics;
        this.#actions.reportDiscordUserJoined({ instanceId, userId });
      }

      if (
        !this.isHost ||
        this.#activityState?.activityMode === hostModes.shared
      ) {
        this.#maybeUpdateListening();
      }

      this.#debug('Activity state updated');
    } catch (error) {
      if (this.#isInitialized) {
        this.#logError('Error processing activity state update', error);
      } else {
        this.#onFatalError(
          'Error processing activity state update before initialization',
          error,
        );
      }
    }
  };

  async #subscribeToActivityState() {
    this.#unsubscribeFromPreviousActivity = this.#unsubscribeFromActivity;

    this.#unsubscribeFromActivity = await this.#api
      .discordActivitySubscribe(
        {
          request: {
            joinRequest: {
              activityId: this.#discordSdk.instanceId,
              discordUserId: this.#userInfo.id,
            },
          },
        },
        this.#onSubscribeUpdate,
      )
      .catch((error) => {
        error.customMessage = 'Error subscribing to activity state';
        throw error;
      });

    this.#debug('Activity subscription requested');
  }

  async #setListening() {
    const { guideId, playSpeed, streamOffsetMs } =
      this.#cachedActivityListening;

    try {
      await this.#api.discordActivitySetCurrentListening({
        request: {
          context: this.#sessionContext,
          listening: { guideId, playSpeed, streamOffsetMs },
        },
      });
      this.#debug('Successfully set listening', this.#cachedActivityListening);
    } catch (error) {
      this.#onFatalError('Error setting listening', error);
    }
  }

  #requestTune(guideId, playFromPosition) {
    const { resolve, promise } = Promise.withResolvers();

    this.#requestTunePromise = promise;

    const requestTune = () =>
      this.#actions
        .tuneWithGuideId({
          guideId,
          playFromPosition,
          isDiscordSyncRequest: true,
        })
        .catch(() => {});
    const onFinishIntervalId = setInterval(() => {
      if (RESOLVED_PLAYER_STATUSES.includes(this.#store.playerStatus)) {
        clearInterval(onFinishIntervalId);
        resolve();
      }
    }, 100);

    if (this.#store.isTunerReady) {
      requestTune();
      return;
    }

    const requestTuneIntervalId = setInterval(() => {
      if (this.#store.isTunerReady) {
        clearInterval(requestTuneIntervalId);
        requestTune();
      }
    }, 100);
  }

  #maybeUpdateListening = debounce(async () => {
    clearTimeout(this.#updateListeningTimeoutId);

    const { listening: activityListening } = this.#activityState || {};

    if (!activityListening) {
      return;
    }

    // Retry state synchronization after 5 seconds, but not if the player is in a failed state. We want to avoid
    // cyclically retrying failed stations automatically. All users have the ability to perform a manual retry.
    if (this.#store.playerStatus !== playerStatuses.failed) {
      this.#updateListeningTimeoutId = setTimeout(
        this.#maybeUpdateListening,
        5000,
      );
    }

    const {
      guideId: previousGuideId,
      playSpeed: previousPlaySpeed,
      streamOffsetMs: previousStreamOffsetMs,
      timestamp: previousTimestamp,
    } = this.#cachedActivityListening;
    const { guideId, playSpeed, streamOffsetMs, timestamp } = activityListening;

    // Ignore potentially out-of-order state updates
    if (
      previousTimestamp &&
      Number(new Date(previousTimestamp)) > Number(new Date(timestamp))
    ) {
      return;
    }

    const hasNewGuideId = previousGuideId !== guideId;
    const hasNewPlaySpeed = previousPlaySpeed !== playSpeed;
    const hasNewStreamOffsetMs = previousStreamOffsetMs !== streamOffsetMs;

    if (!(hasNewGuideId || hasNewPlaySpeed || hasNewStreamOffsetMs)) {
      return;
    }

    this.#cachedActivityListening = this.#activityState.listening;

    // Promise created in #requestTune() is used to ensure that we synchronously tune before processing other state updates
    await this.#requestTunePromise;

    // If several state updates are received in quick succession while waiting for the tune promise resolution,
    // we only want to process the last one
    if (this.#cachedActivityListening !== this.#activityState.listening) {
      return;
    }

    const shouldLoadNewGuideId =
      hasNewGuideId && activityListening.playSpeed !== playSpeeds.pause;
    const shouldPlay =
      hasNewPlaySpeed && previousPlaySpeed === playSpeeds.pause;

    if (
      shouldLoadNewGuideId ||
      (shouldPlay && stoppedPlayerStatuses[this.#store.playerStatus])
    ) {
      this.#requestTune(
        activityListening.guideId,
        streamOffsetToSeconds(timestamp, streamOffsetMs),
      );
      return;
    }

    // If the player is in a failed state, we only want to modify the listening state when a new guide id is received (handled above)
    if (this.#store.playerStatus === playerStatuses.failed) {
      return;
    }

    if (
      hasNewPlaySpeed &&
      playSpeed === playSpeeds.pause &&
      this.#store.playerStatus === playerStatuses.playing
    ) {
      if (this.#store.positionInfo?.duration) {
        return this.#actions.pause(true);
      }

      return this.#actions.stop(true);
    }

    if (shouldPlay) {
      return this.#actions.play({ isDiscordSyncRequest: true });
    }

    if (hasNewPlaySpeed) {
      return this.#actions.changePlaybackRateIfPossible(
        playSpeedEnumToPlayRateMap[playSpeed] || playRates.one,
      );
    }

    if (hasNewStreamOffsetMs) {
      const seekPercentageInt = streamOffsetToPercentageInt(
        timestamp,
        streamOffsetMs,
        this.#store.positionInfo?.duration,
      );

      return this.#actions.seek(seekPercentageInt, true);
    }
  }, 200);

  /**
   * These might not be necessary, as the Discord client should close the activity automatically when needed. We can
   * revisit later when we have more confidence that this isn't needed.
   */
  #setupDiscordClientCloseHandler() {
    window.addEventListener('beforeunload', () => {
      if (this.#sessionId) {
        this.destroy();
      }
    });
    window.addEventListener('message', (event) => {
      if (event.origin !== 'https://discord.com' || !this.#sessionId) {
        return;
      }

      // This is sent when the Discord client is closed.
      if (event.data?.[0] === discordPostMessageEvents.close) {
        this.destroy();
      }
    });
  }

  async #reportActivityClose() {
    const { instanceId, userId, duration } = this.#reportingMetrics;

    await Promise.all([
      this.#actions.reportDiscordUserLeft({ instanceId, userId, duration }),
      this.#participants.length === 1 &&
        this.#actions.reportDiscordActivityEnded({ instanceId, duration }),
    ]).catch(() => {});
  }

  // TODO: after we get enough data, we can update how we handle the different states.
  #onThermalStateUpdate = async (update) => {
    // Note: library has import side effects that references window. Don't import directly in files that run on the server.
    const { Common } = await import('@discord/embedded-app-sdk');

    switch (update?.thermal_state) {
      case Common.ThermalStateTypeObject.SERIOUS:
        this.#debug('Thermal state: SERIOUS');
        break;
      case Common.ThermalStateTypeObject.CRITICAL:
        this.#debug('Thermal state: CRITICAL');
        break;
      default:
    }
  };

  async #registerDiscordEvents() {
    // Note: library has import side effects that references window. Don't import directly in files that run on the server.
    const { Events } = await import('@discord/embedded-app-sdk');

    this.#discordSdk.subscribe(
      Events.THERMAL_STATE_UPDATE,
      this.#onThermalStateUpdate,
    );
  }

  #closeActivity() {
    this.#discordSdk?.close?.(discordCloseCodes.normal);
  }

  get isHost() {
    return this.#userInfo.id === this.#activityState?.hostId;
  }

  /**
   * The `ready` event is emitted in #onSubscribeUpdate when the activity state is first received.
   *
   * @param apiClient - Web Api Client instance
   * @param actions - Redux actions
   * @param stateRef - Redux store state in a React Ref (see useDiscord.js)
   * @param isDebugModeEnabled - see #setDebugMode() in this file for more info
   * @returns {Promise<void>}
   */
  async init({ apiClient, actions, stateRef, isDebugModeEnabled }) {
    try {
      this.#api = apiClient.graphQlClient.sdk;
      this.#actions = actions;
      this.#stateRef = stateRef;

      this.#setDebugMode(isDebugModeEnabled);
      this.#setupDiscordClientCloseHandler();
      await this.#initDiscordSdk();
      await this.#authenticate();
      await this.#subscribeToParticipants();

      if (this.#participants.length >= this.#store.maxPartySize) {
        const { instanceId, userId } = this.#reportingMetrics;

        this.#actions.reportMaxParticipantsReached({ instanceId, userId });
        return this.emit('maxParticipantsReached');
      }

      await this.#subscribeToActivityState();
      this.#registerDiscordEvents();
    } catch (error) {
      this.#onFatalError('Error initializing DiscordManager', error);
    }
  }

  /**
   * @returns success {Promise<boolean>}
   */
  async setHost(newHostId) {
    this.#isSettingHost = true;

    try {
      await this.#api.discordActivitySetHost({
        request: { newHostId, context: this.#sessionContext },
      });
      this.#debug('Successfully set host', newHostId);

      this.#actions.reportDiscordHostAssigned({
        instanceId: this.#reportingMetrics.instanceId,
        userId: newHostId,
      });

      this.#isSettingHost = false;

      return true;
    } catch (error) {
      this.#isSettingHost = false;
      this.#logError('Error setting host', error);

      return false;
    }
  }

  async setMode(mode) {
    try {
      await this.#api.discordActivitySetMode({
        request: { mode, context: this.#sessionContext },
      });
      this.#debug('Successfully set mode', mode);

      const { instanceId, modeDuration } = this.#reportingMetrics;

      if (mode === hostModes.shared) {
        return this.#actions.reportDiscordSharedModeActivated({
          instanceId,
          duration: modeDuration,
        });
      }

      this.#actions.reportDiscordSharedModeDeactivated({
        instanceId,
        duration: modeDuration,
      });
    } catch (error) {
      this.#logError('Error setting mode', error);
    }
  }

  setListeningPosition() {
    this.#cachedActivityListening.streamOffsetMs = Math.round(
      this.#store.positionInfo?.elapsedSeconds * 1000 || 0,
    );

    this.#setListening();
  }

  openExternalLink(url) {
    this.#discordSdk.commands.openExternalLink({
      url,
    });
  }

  setListening({ guideId = '', isPlaying, playbackRate }) {
    const nextListening = {
      guideId: guideId || this.#cachedActivityListening.guideId,
      streamOffsetMs: Math.round(
        this.#store.positionInfo?.elapsedSeconds * 1000 || 0,
      ),
      // playSpeed is typically a multiple like 0.5x/1x/2x, but can also be pause, which is effectively 0x.
      // Defaulting to pause, but conditionally updated below.
      playSpeed: playSpeeds.pause,
    };

    // Attempt to convert playbackRate to a valid playSpeed enum for the API
    //  Otherwise, use the last known playSpeed enum
    //  Otherwise, if none exists, fallback to 1x
    if (isPlaying) {
      nextListening.playSpeed =
        (playbackRate
          ? playRateToPlaySpeedEnumMap[playbackRate]
          : this.#cachedActivityListening.playbackRate) || playSpeeds.one;
    }

    // streamOffsetMs updates are triggered via user-initiated seek and are handled by setListeningPosition()
    const hasListeningChanged =
      nextListening.playSpeed !== this.#cachedActivityListening.playSpeed ||
      nextListening.guideId !== this.#cachedActivityListening.guideId;

    if (hasListeningChanged) {
      this.#cachedActivityListening = nextListening;

      this.#setListening();
    }
  }

  setRichPresence(details, nowPlayingTitle) {
    this.#discordSdk.commands.setActivity(
      getRichPresenceState(details, nowPlayingTitle),
    );
  }

  destroy = async () => {
    this.removeAllListeners();
    this.#unsubscribeFromActivity?.();
    await this.#reportActivityClose();
    this.#closeActivity();
  };
}

export const discordManager = new DiscordManager();
