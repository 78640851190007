import flow from 'lodash/flow';
import withAdScreenRequest from '../../decorators/ads/withAdScreenRequest';
import connectAsync from '../../utils/connectAsync';
import fetchCategory from '../../utils/connectors/fetchCategory';

// e.g: /podcasts/, /audiobooks/
import { createRootCategoryContext } from '../../routes/utils/getBrowseContext';
import BrowseLoader from './BrowseLoader';

export default flow(
  withAdScreenRequest(() => 'browse'),
  connectAsync([fetchCategory(createRootCategoryContext)]),
)(BrowseLoader);
