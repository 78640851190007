import PropTypes from 'prop-types';
import { useContext } from 'react';
import {
  DISCORD_SPLASH_MAX_PARTICIPANTS,
  DISCORD_SPLASH_PICTURE_IN_PICTURE,
} from '../../../constants/localizations/discord';
import { LocationAndLocalizationContext } from '../../../providers/LocationAndLocalizationProvider';
import cssVariables from '../../../styles/variables';
import assetUrl from '../../../utils/assetUrl';
import { NavHomeTuneInLink } from '../../shared/link/NavHomeTuneInLink';
import TuneInMarquee from '../../shared/svgIcons/TuneInMarquee';
import { splashScreenTypes } from '../constants';
import css from './discordSplash.module.scss';

export function DiscordSplash({ splashScreenType }) {
  const { getLocalizedText } = useContext(LocationAndLocalizationContext);

  return (
    <div id="discord-splash" className={css.discordSplash}>
      {!splashScreenType && (
        <>
          <div className={css.loading}>
            <TuneInMarquee fill={cssVariables['--anti-flash-white']} />
          </div>
          <div className={css.spinner} />
        </>
      )}
      {splashScreenType === splashScreenTypes.pictureInPicture && (
        <>
          <div className={css.tiBadge}>
            <NavHomeTuneInLink />
          </div>
          <div className={css.center}>
            <img src={assetUrl('assets/img/headphones.png')} alt="Headphones" />
            <span>{getLocalizedText(DISCORD_SPLASH_PICTURE_IN_PICTURE)}</span>
          </div>
        </>
      )}
      {splashScreenType === splashScreenTypes.maxParticipants && (
        <>
          <div className={css.tiBadge}>
            <NavHomeTuneInLink />
          </div>
          <div className={css.center}>
            <img src={assetUrl('assets/img/error/radio.png')} alt="Radio" />
            <span>{getLocalizedText(DISCORD_SPLASH_MAX_PARTICIPANTS)}</span>
          </div>
        </>
      )}
    </div>
  );
}

DiscordSplash.propTypes = {
  splashScreenType: PropTypes.string,
};
