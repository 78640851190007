import { mapKeys } from '@tunein/web-utils';
import config from 'src/common/config';
import { playerStatuses } from '../../../constants/playerStatuses';
import vars from '../../../vars';

const { discordProxyPath } = config;

export const DISCORD_CLIENT_ID = vars.get('DISCORD_CLIENT_ID');
export const discordSdkConfig = {
  disableConsoleLogOverride: true, // prevents console logs from being duplicated by the Discord client
};
export const authConfig = {
  client_id: DISCORD_CLIENT_ID,
  response_type: 'code',
  state: '',
  prompt: 'none',
  // More info on scopes here: https://discord.com/developers/docs/topics/oauth2#shared-resources-oauth2-scopes
  scope: [
    'identify',
    'guilds.members.read',
    'rpc.activities.write',
    // Activities will launch through app commands and interactions of user-installable apps.
    // https://discord.com/developers/docs/tutorials/developing-a-user-installable-app#configuring-default-install-settings-adding-default-install-settings
    'applications.commands',
  ],
};
export const MAX_DISCORD_ACTIVITY_API_RETRIES = 3;
// In HOSTED mode, other users cannot modify shared state, like player controls and station selection.
export const hostModes = {
  hosted: 'HOSTED',
  shared: 'SHARED',
};
// Discord does not expose an enum for this.
export const discordPostMessageEvents = {
  close: 2,
};
// Discord does not expose an enum for this.
export const discordCloseCodes = {
  normal: 1000, // per docs, this is the only close code that does not surface a modal with the error message
  abnormal: 1006,
};
// These are not documented/exported by Discord, so we'll keep our own map.
export const discordErrorCodes = {
  oauthDeniedViaOutsideModalClick: 1000,
  oauthDeniedViaButtonClick: 5000,
};

export const discordErrorMessages = {
  [discordErrorCodes.oauthDeniedViaOutsideModalClick]:
    'User clicked outside of oauth modal',
  [discordErrorCodes.oauthDeniedViaButtonClick]: 'User clicked cancel button',
};

// URL mappings are an allow-list of paths that are registered in the Discord Proxy to map to external domains.
// See docs/features/DISCORD.md for more information.
export const URL_MAPPINGS = [
  // this is used for link/script tags, as Discord prefers not to bypass CSP for these
  { prefix: '/cdn/web', target: 'cdn-web.tunein.com' },
  // TODO: audit the mappings below to see if they're still needed
  { prefix: '/googleapis/{server}', target: '{server}.googleapis.com' },
  {
    prefix: '/adswizz/delivery-engine',
    target: 'tunein.deliveryengine.adswizz.com',
  },
];

// We don't have a current need for URL mappings on Web Socket connections.
export const URL_MAPPINGS_OPTIONS = {
  patchWebSocket: false,
};

// This is a map of TuneIn-domains-that-host-images to Discord-proxy-paths, which are registered in the Discord dashboard.
// See docs/features/DISCORD.md for more information.
// One use case for this image mapping is to transform image URLs for the color-palette-fetching library.
export const imageDomainToProxyPathMap = {
  'cdn-profiles-dev.tunein.com': `/${discordProxyPath}/cdn/profiles`,
  'cdn-profiles.tunein.com': `/${discordProxyPath}/cdn/profiles`,
  'cdn-albums-dev.tunein.com': `/${discordProxyPath}/cdn/albums`,
  'cdn-albums.tunein.com': `/${discordProxyPath}/cdn/albums`,
  'cdn-radiotime-logos.tunein.com': `/${discordProxyPath}/cdn/logos`,
  'cdn-web.tunein.com': `/${discordProxyPath}/cdn/web`,
};

// These values are formatted for the DiscordActivitySetCurrentListening GraphQl mutation.
export const playSpeeds = {
  pointFive: 'POINT_FIVE',
  pointEight: 'POINT_EIGHT',
  one: 'ONE',
  onePointTwo: 'ONE_POINT_TWO',
  onePointFive: 'ONE_POINT_FIVE',
  onePointEight: 'ONE_POINT_EIGHT',
  two: 'TWO',
  three: 'THREE',
  pause: 'PAUSE',
};
// These values are formatted for the player API.
export const playRates = {
  pointFive: 0.5,
  pointEight: 0.8,
  one: 1,
  onePointTwo: 1.2,
  onePointFive: 1.5,
  onePointEight: 1.8,
  two: 2,
  three: 3,
};
// Mapping DiscordActivitySetCurrentListening enum values to player API values.
export const playSpeedEnumToPlayRateMap = mapKeys(
  playRates,
  (value, key) => playSpeeds[key] || key,
);
// Mapping player API values to DiscordActivitySetCurrentListening enum values.
export const playRateToPlaySpeedEnumMap = mapKeys(
  playSpeeds,
  (value, key) => playRates[key] || key,
);

// These player statuses indicate when we can consider a tune request to have finished
export const RESOLVED_PLAYER_STATUSES = [
  playerStatuses.failed,
  playerStatuses.playing,
  playerStatuses.stopped,
];
